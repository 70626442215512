import React, { useEffect, useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from '../Assets/Images/logo.png';
import dextoolsBlack from '../Assets/Images/dextoolsBlack.png';
import WOW from 'wowjs'
import ROUTES from '../Configs/Routes';

const Header = () => {
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('.content-section');
      sections.forEach(section => {
        const sectionId = section.getAttribute('id');
        const rect = section.getBoundingClientRect();
        if (rect.top <= window.innerHeight / 1.5 && rect.bottom >= 0) {
          setActiveSection(sectionId);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const wow = new WOW.WOW({
      boxClass: 'wow', // default
      animateClass: 'animate__animated', // default
      offset: 0, // default
      mobile: false, // default
      live: true, // default
    });
    wow.init();
  }, [])

  return (
    <>
      <Navbar expand='lg' className='mainNavbar py-1 py-lg-0'>
        <Container className=''>
          <Navbar.Brand>
            <Link to={ROUTES.HOME} >
              <img src={logo} alt='logo' className='headerLogo' />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='navbarScroll' />
          <Navbar.Collapse id='navbarScroll'>
            <Nav className='mx-auto	align-items-center'>
              <Nav.Link href='#about' className={activeSection === 'about' ? 'active2' : ''}>About Us</Nav.Link>
              <Nav.Link href='#tokenomics' className={activeSection === 'tokenomics' ? 'active2' : ''}>Tokenomics</Nav.Link>
              <Nav.Link href='#buyToken' className={activeSection === 'buyToken' ? 'active2' : ''}>How To Buy?</Nav.Link>
              <Nav.Link href='#community' className={activeSection === 'community' ? 'active2' : ''}>Community</Nav.Link>
            </Nav>
            <a href='https://www.dextools.io/app/en/solana/pair-explorer/25QDv6EqTLqh93tiPRyRnmmSiUGRkyKgw8HzncyxGYXT?t=1714195455092' target='_blank' rel='noreferrer' className='themeBtn dexBtn d-block mx-auto mx-lg-2'>
              <img src={dextoolsBlack} alt='dextools' />
            </a>
            <a href='#link' target='_blank' rel='noreferrer' className='themeBtn headerBtn d-block mx-auto mx-lg-0'>Buy $DrCroc</a>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default Header
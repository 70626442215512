import React from 'react'
import { Container } from 'react-bootstrap'

const Footer = () => {
  return (
    <>
      <div className='footerSec py-4'>
        <Container>
          Copyright © 2024 Dr. Crocodile. All Rights Reserved
        </Container>
      </div>
    </>
  )
}

export default Footer
import React from 'react';
import { Button, Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import bannerImg from '../Assets/Images/bannerImg.png';
import twitter from '../Assets/Images/Icons/twitter.png';
import telegram from '../Assets/Images/Icons/telegram.png';
import medium from '../Assets/Images/Icons/medium.png';
import reddit from '../Assets/Images/Icons/reddit.png';
import coingecko from '../Assets/Images/coingecko.png';
import dexscreener from '../Assets/Images/dexscreener.png';
import gotbit from '../Assets/Images/gotbit.png';
import dextools from '../Assets/Images/dextools.png';
import dexview from '../Assets/Images/dexview.png';
import aboutImg from '../Assets/Images/aboutImg.png';
import availDivBg from '../Assets/Images/Backgrounds/availDivBg.png';
import mexc from '../Assets/Images/mexc.png';
import raydium from '../Assets/Images/raydium.png';
import bybit from '../Assets/Images/bybit.png';
import bitmart from '../Assets/Images/bitmart.png';
import bitget from '../Assets/Images/bitget.png';
import jupiter from '../Assets/Images/jupiter.png';
import gateio from '../Assets/Images/gateio.png';
import tokenTxtBg from '../Assets/Images/Backgrounds/tokenTxtBg.png';
import tokenImg from '../Assets/Images/tokenImg.png';
import CopyToClipboard from 'react-copy-to-clipboard';

const Home = () => {
  const partnerItems = [coingecko, dexscreener, gotbit, dextools, dexview];
  const availItems = [mexc, raydium, jupiter, bybit, bitmart, bitget, gateio];

  const contractAddress = '3k5dEDX2UCwCYL13fSXnSgZVxnixt847SDpt9PfEAueu';
  const tokenData = [{ title: 'Tax', data: '0/0' }, { title: 'MINT & FREEZE', data: 'REVOKED' }, { title: 'LIQUIDITY', data: 'BURNED' }, { title: 'TOTAL SUPPLY', data: '410,000,000' }];


  const options = {
    gap: '0px',
    type: 'loop',
    drag: 'free',
    perPage: 5,
    autoplay: true,
    interval: 0,
    speed: 25000,
    easing: 'linear',
    breakpoints: {
      // 1199: {
      //   perPage: 4,
      // },
      991: {
        perPage: 4,
        speed: 20000,
      },
      767: {
        perPage: 3,
      },
      575: {
        perPage: 2,
        speed: 15000,
      },
    },
    arrows: false,
    pagination: false,
    // easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    // easingFunc: t => 1 - Math.pow(1 - t, 3)
  };

  return (
    <>
      {/* bannerSec start */}
      <div className='bannerSec position-relative overflow-hidden'>
        <Container className='position-relative'>
          <Row className='align-items-center gy-4 gy-sm-0'>
            <Col md={7} xl={6}>
              <div className='bannerTxt text-center text-md-start wow animate__animated animate__fadeInLeft'>
                <h1 className='bannerTitle'>I'm Dr. Crocodile</h1>
                <p className='my-3 my-md-4'>Dr. Croc, the adorable and playful meme token on the Solana blockchain, is here to spread joy, laughter, and financial growth! As the Dean of Solana Hospital, I'm thrilled to lead this community-driven project that aims to enrich lives beyond healthcare. Join us on this exciting journey where smiles meet smart investments!</p>
                {/* 🐊💼🏥 */}
                <Button className='themeBtn'>Buy $DrCroc</Button>
              </div>
              <div className='socialDiv mb-3 mb-md-0'>
                {/* <h4 className='fw-light mb-3 wow animate__animated animate__fadeInUp'>Join Our Community</h4> */}
                <div className='socialLinks d-flex flex-wrap flex-md-column justify-content-center gap-3 gap-lg-4'>
                  <a href='https://twitter.com/DrCroc_Meme' target='_blank' rel='noreferrer'><img src={twitter} alt='twitter' /></a>
                  <a href='https://t.me/DrCroc_NursingStaff' target='_blank' rel='noreferrer'><img src={telegram} alt='telegram' /></a>
                  <a href='https://medium.com/@Dr.Croc' target='_blank' rel='noreferrer'><img src={medium} alt='medium' /></a>
                  <a href='https://www.reddit.com/user/DrCroc_Meme/' target='_blank' rel='noreferrer'><img src={reddit} alt='reddit' /></a>
                </div>
              </div>
            </Col>
            <Col xs={10} sm={8} md={5} className='mx-auto'>
              <div className='bannerImg wow animate__animated animate__fadeInRight'>
                <img src={bannerImg} alt='Dr.Crocodile' className='d-block mx-auto ms-md-0' />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* bannerSec end */}

      {/* partnerSec start */}
      <div className='partnerSec overflow-hidden mt-lg-5'>
        <h2 className='mainTitle mb-4 wow animate__animated animate__flipInX'>Our Partner's</h2>

        <div className='partnercarousel'>
          <Container className=''>
            <Splide options={options}>
              {partnerItems.map((item, index) => {
                return (
                  <SplideSlide key={index} className='d-flex align-items-center justify-content-center'>
                    <img src={item} alt='partners' className='d-block mx-auto w-100' />
                  </SplideSlide>
                )
              })}
            </Splide>
          </Container>
        </div>
      </div>
      {/* partnerSec end */}

      {/* aboutSec start */}
      <div className='aboutSec position-relative overflow-hidden mt-3 mb-4 my-sm-5 content-section' id='about'>
        <Container>
          <Row className='align-items-center justify-content-center'>
            <Col xs={8} sm={7} md={6} lg={4} xl={5}>
              <div className='aboutImg wow animate__animated animate__fadeInLeft'>
                <img src={aboutImg} alt='Dr.Crocodile' className='d-block mx-auto' />
              </div>
            </Col>
            <Col lg={8} xl={7}>
              <div className='aboutTxt wow animate__animated animate__fadeInRight'>
                <h2 className='mainTitle mb-4'>About Dr. Crocodile</h2>
                <p>Welcome To $DrCroc, The Jungle Addition To The Solana Blockchain! Our Small Yet Mighty Peacock, With A Hint Of The Frog, Brings a Fresh Breeze Of Creativity To The Degen World Of Solana. As The Newest And Freshest Meme Token, $DrCroc Is Here Spice Things Up. Join Us In Bringing Warmth And Fathering Liquidity To The Frosty Solana Chain.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* aboutSec end */}

      {/* availableSec start */}
      <div className='availableSec overflow-hidden pt-3 py-lg-5'>
        <Container>
          <h2 className='mainTitle mb-4 mb-sm-5 wow animate__animated animate__flipInX'>Available On</h2>

          <Row className='justify-content-center gy-4'>
            {availItems.map((item, id) => {
              return (
                <Col xs={10} sm={6} md={5} lg={3} key={id}>
                  <div className='availableDiv position-relative wow animate__animated animate__pulse' data-wow-delay={`${id / 10}s`}>
                    <img src={availDivBg} alt='' className='availBg w-100' />
                    <img src={item} alt='' className='availImg' />
                  </div>
                </Col>
              )
            })}
          </Row>
        </Container>
      </div>
      {/* availableSec end */}

      {/* tokenSec start */}
      <div className='tokenSec position-relative overflow-hidden content-section' id='tokenomics'>
        <Container>
          <h2 className='mainTitle mb-4 wow animate__animated animate__flipInX'>Croconomics</h2>

          <div className='contractAddress mb-4 mb-md-5 wow animate__animated animate__jackInTheBox'>CONTRACT :<span className='mx-1 mx-sm-3 my-1 my-sm-0'>{contractAddress}</span>
            <CopyToClipboard text={contractAddress}>
              <Button>Copy</Button>
            </CopyToClipboard>
          </div>

          <Row className='justify-content-center align-items-center gy-4'>
            <Col lg={6}>
              <Row className='justify-content-center'>
                {tokenData.map((item, id) => {
                  return (
                    <Col sm={5} lg={6} xxl={5} key={id} className='px-sm-0'>
                      <div className='tokenDiv position-relative wow animate__animated animate__zoomIn'>
                        <img src={tokenTxtBg} alt='' />
                        <div className='tokenTxt'>
                          <p>{item.title}</p>
                          <h2>{item.data}</h2>
                        </div>
                      </div>
                    </Col>
                  )
                })}
              </Row>
            </Col>
            <Col sm={7} md={6} lg={5}>
              <div className='tokenImg wow animate__animated animate__pulse'>
                <img src={tokenImg} alt='' className='d-block mx-auto' />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* tokenSec end */}

      {/* buySec start */}
      <div className='buySec position-relative overflow-hidden content-section' id='buyToken'>
        <Container>
          <h2 className='mainTitle mb-4 mb-md-5 wow animate__animated animate__flipInX'>How To Buy?</h2>

          <div className='buyTabs d-flex flex-wrap gap-3 gap-lg-4'>
            <Tabs defaultActiveKey="step1" className='wow animate__animated animate__fadeInLeft'>
              <Tab eventKey="step1" title="Step 1">
                <div className='buyContent'>
                  <h2 className='buyNum'>Step 1</h2>
                  <h3 className='mb-2 mb-lg-3'>DOWNLOAD PHANTOM EXTENTION</h3>
                  <p>Download And Install Phantom On The Appstore or Alternatively If You're On Desktop Download And Install The Browser Extention.</p>
                </div>
              </Tab>
              <Tab eventKey="step2" title="Step 2">
                <div className='buyContent'>
                  <h2 className='buyNum'>Step 2</h2>
                  <h3 className='mb-2 mb-lg-3'>SECURE YOUR SOL</h3>
                  <p>Now All You've Got To Do Is Buy Your SOL in The PHANTOM APP Or Alternatively You Can Use An Exchange And Deposit Using Your Wallet Address.</p>
                </div>              </Tab>
              <Tab eventKey="step3" title="Step 3">
                <div className='buyContent'>
                  <h2 className='buyNum'>Step 3</h2>
                  <h3 className='mb-2 mb-lg-3'>BUY SOME $DrCroco</h3>
                  <p>Now Go To Raydium And Jupiter Paste The Dr. Crocodile Contract Address To Swap You SOL.</p>
                  <div className='contractAddress'>CONTRACT :<span className='mx-1 mx-sm-3 my-1 my-sm-0'>{contractAddress}</span>
                    <CopyToClipboard text={contractAddress}>
                      <Button>Copy</Button>
                    </CopyToClipboard>
                  </div>
                </div>              </Tab>
              <Tab eventKey="step4" title="Step 4">
                <div className='buyContent'>
                  <h2 className='buyNum'>Step 4</h2>
                  <h3 className='mb-2 mb-lg-3'>ADD TO YOUR WALLET</h3>
                  <p>Now You're All Set! Welcome Aboard The Next Rocket To The Moon, Just Import The Contract Address To View You're Holdings And You Can Track Your Earnings.</p>
                </div>
              </Tab>
            </Tabs>
          </div>
        </Container>
      </div>
      {/* buySec end */}

      {/* communitySec start */}
      <div className='communitySec overflow-hidden py-5 mt-4 mt-lg-5 content-section' id='community'>
        <Container>
          <h2 className='mainTitle mb-4 wow animate__animated animate__flipInX'>Join Our Community!</h2>
          <div className='communityLinks d-flex flex-wrap gap-3 gap-lg-4 justify-content-center wow animate__animated animate__pulse'>
            <a href='https://twitter.com/DrCroc_Meme' target='_blank' rel='noreferrer'><img src={twitter} alt='twitter' /> Twitter</a>
            <a href='https://t.me/DrCroc_NursingStaff' target='_blank' rel='noreferrer'><img src={telegram} alt='telegram' /> Telegram</a>
            <a href='https://medium.com/@Dr.Croc' target='_blank' rel='noreferrer'><img src={medium} alt='medium' /> Medium</a>
            <a href='https://www.reddit.com/user/DrCroc_Meme/' target='_blank' rel='noreferrer'><img src={reddit} alt='reddit' /> Reddit</a>
          </div>

          <a href='mailto:dean@dr-croc.meme' className='d-block text-center mt-4 fs-5'>Email: dean@dr-croc.meme</a>
        </Container>
      </div>
      {/* communitySec end */}
    </>
  )
}

export default Home
import React, { useState } from 'react'
import MainContext from './MainContext'

const ContextState = (props) => {

    const [state1, setState1] = useState('hello');

    const value = {
        state1, setState1
    }

    return (
        <MainContext.Provider value={value}>
            {props.children}
        </MainContext.Provider>
    )
}

export default ContextState